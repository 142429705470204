function buzzBee(element) {
  const moveDuration = 10000; // Duration of each move in milliseconds
  const areaSize = 100; // Area size in pixels
  const circleRadius = 20; // Radius of the circular path in pixels
  let centerX = 0;
  let centerY = 0;
  let startTime;

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  function setNewCenter() {
    centerX = randomInRange(circleRadius, areaSize - circleRadius);
    centerY = randomInRange(circleRadius, areaSize - circleRadius);
    startTime = performance.now();
  }

  function move(timestamp) {
    if (!startTime) startTime = timestamp;
    const progress = (timestamp - startTime) / moveDuration;
    const angle = progress * 2 * Math.PI;

    // Create a circular path
    const x = centerX + circleRadius * Math.cos(angle);
    const y = centerY + circleRadius * Math.sin(angle);

    element.style.transform = `translate(${x}px, ${y}px)`;

    // Change the center point periodically
    if (progress >= 1) {
      //setNewCenter();
    }

    requestAnimationFrame(move);
  }

  setNewCenter();
  requestAnimationFrame(move);
}

export function buzz() {
  const buzz = document.querySelectorAll(".buzz");
  if (buzz.length === 0) return;
  buzz.forEach((el) => {
    buzzBee(el);
  });
}
