import springer from "springer";
export function testimonial() {
  const testimonial = document.querySelector(".testimonial---section");
  if (!testimonial) return;

  const testimonialImgs = testimonial.querySelectorAll(".roundedbubble");
  const testimonialContent = testimonial.querySelector(
    ".rl_section_testimonial15"
  );

  const tlNoScrub = gsap.timeline({
    scrollTrigger: {
      trigger: testimonial,
      start: "center bottom",
      end: "bottom top",
      once: true,
    },
  });
  const tlLong = gsap.timeline({
    scrollTrigger: {
      trigger: testimonial,
      start: "top bottom",
      end: "bottom top",
      scrub: true,
    },
  });

  const springBubble = springer(0.5, 0.65);


  tlLong.add(
    gsap.fromTo(
      testimonialImgs,
      {
        y: -100,
      },
      {
        y: 100,
        force3D: true,
      }
    )
  );

  tlNoScrub
    .fromTo(
      testimonialImgs,
      { opacity: 0, scale: 0 },
      { opacity: 1, scale: 1, stagger: 0.1, ease: springBubble, duration: 1 }
    )
    .fromTo(
      testimonialContent,
      {
        y: 50,
        opacity: 0,
        scale: 0.8,
      },
      {
        y: 0,
        opacity: 1,
        scale: 1,
        duration: 1.8,
        ease: springBubble,
      }, '-=1'
    );
}
