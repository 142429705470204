export function next() {
  const sections = document.querySelectorAll('[fs-cmsload-mode="load-under"]');
  const nextButton = document.querySelector(
    ".w-pagination-next, .w-pagination-previous"
  );

  if (!sections || !nextButton) return;
  if (window.location.href.includes("?")) {

    let section = sections[0]

    let margin = document.querySelector('.wrapper-header-blog-2') ? 300 : -100

    if(window.location.href.includes("?e7c5137a")) {
      section = sections[0]
      margin = 4000
    }

    if(window.location.href.includes("?631525a7")) {
      section = sections[1]
      margin = 8000
    }

    window.scrollTo({
      top: section?.offsetTop + margin,
    });
  }
}
