import { isMobile } from "../libs/devices";
export function balance() {
  // Select the element you want to animate
  const rotatingElements = document.querySelectorAll(".balance");
  if (!rotatingElements) return;
  rotatingElements.forEach((rotatingElement) => {
    // Define your animation using GSAP
    gsap.set(rotatingElement, {
      transformOrigin: "top", // Set the rotation point
      rotation: 25, // Initial rotation value in degrees
      top: isMobile() ? -20 : -40,
      position: "relative",
      willChange: "transform",
    });
    const rotatingTimeline = gsap.timeline({
      repeat: -1,
      yoyo: true,
    });
    // Animate from 25 to 0 degrees
    rotatingTimeline.to(rotatingElement, {
      duration: 1,
      rotation: 0,
      ease: "none",
    });

    // Animate from 0 to 325 degrees in reverse
    rotatingTimeline.to(rotatingElement, {
      duration: 1,
      rotation: -25,
      ease: "none",
    });
  });
}
