export function animateNumber(element, fromValue, toValue) {
  gsap.to(element, {
    duration: 1.3,
    innerHTML:
      toValue > 9999
        ? toValue.toLocaleString("en-EN", {
            notation: "compact",
            compactDisplay: "short",
          })
        : toValue,
    snap: "innerHTML",
    onComplete: () => {
      fromValue = toValue;
    },
    ease: "power4.out",
  });
}
