import springer from "springer";

export function gridMembers() {
  const gridMembers = document.querySelector(".wrapper-grid---members");
  if (!gridMembers) return;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: gridMembers,
      start: "top bottom",
      end: "center center",
      once: true,
    },
  });

  const springBubble = springer(0.5, 0.65);

  tl.add(
    gsap.fromTo(
      gridMembers.querySelectorAll("img"),
      {
        opacity: 0,
        scale: 0,
        rotate: "10deg",
      },
      {
        scale: 1,
        rotate: "0deg",
        opacity: 1,
        force3D: true,
        duration: 1.25,
        stagger: {
          from: "center",
          amount: 0.5,
        },
        ease: springBubble,
      }
    )
  );
}
