export function nav() {
  // Object with the URLs associated with the navigation of "Production" and "Why Letsignit?"
  if (!document.querySelector(".nav-menu-2")) return;
  const urls = {
    product: [
      "fr/produit",
      "product",
      "fr/signatures-mail",
      "email-signatures",
      "fr/campagnes-signatures-mail",
      "email-signatures-campaigns",
      "integrations",
      "fr/integrations",
      "fr/securite-conformite",
      "security-compliance",
    ],
    whyLetsignit: [
      "fr/pourquoi-letsignit",
      "why-letsignit",
      "communication",
      "for-it-team",
      "for-marketing-team",
      "fr/a-propos",
      "about-us",
    ],
    resources: [
      "fr/ressources",
      "resources",
      "fr/ebook",
      "ebook",
      "fr/blog",
      "blog",
      "fr/podcast",
      "podcast",
      "fr/webinar",
      "webinar",
      "fr/presse",
      "press",
    ],
    successStories: ["fr/success-stories", "success-stories"],
  };

  const navItems = {
    product: document.querySelector(
      "nav a[href*='produit'], nav a[href*='product']"
    ).parentNode,
    whyLetsignit: document.querySelector(
      "nav a[href*='pourquoi-letsignit'], nav a[href*='why-letsignit']"
    ).parentNode,
    resources: document.querySelector(
      "nav a[href*='ressource'], nav a[href*='resource']"
    ).parentNode,
    successStories: document.querySelector(
      "nav a[href*='temoignages-success'], nav a[href*='success-stories']"
    ),
  };

  let url = window.location.pathname;

  let activeUrlFound = false;

  // Parcours des clés de l'objet urls
  for (const key in urls) {
    const urlsArray = urls[key];

    // Parcours du tableau de chaque clé
    for (const urlFromArray of urlsArray) {
      if (url.startsWith(`/${urlFromArray}`)) {
        activeUrlFound = true;
        navItems[key].classList.add("w--current");
        break;
      }
    }
    if (activeUrlFound) {
      break;
    }
  }

  function updateDropdown() {
    const dropdownListNavs = document.querySelectorAll(".dropdown-list");
    const menu = document.querySelector(".nav-menu-2");
    const dropdownWithHover = document.querySelectorAll('[data-hover="true"]');
    const isMobile = window.matchMedia("(max-width: 991px)").matches;
  
    menu.style.maxHeight = isMobile ? "100vh" : "";
    menu.style.overflow = isMobile ? "scroll" : "";
  
    dropdownWithHover.forEach(function (element) {
      element.addEventListener("mouseleave", function () {
        setTimeout(function () {
          dropdownListNavs.forEach(function (dropdownListNav) {
            if (dropdownListNav.classList.contains("w--open")) {
              dropdownListNav.classList.remove("w--open");
            } else {
              dropdownListNav.classList.add("w--open");

            }
          });
        }, 0.1);
      });
    });
  
    dropdownListNavs.forEach(function (dropdownListNav) {
      //console.log('second')
      dropdownListNav.classList.toggle("w--open", isMobile);
    });
  }
  
  window.addEventListener("resize", updateDropdown);
  
}
