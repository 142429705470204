import springer from "springer";

export async function support() {
  const supportWrapper = document.querySelectorAll(".wrapper---support");
  const supportCards = document.querySelectorAll(".card---support");

  if (!supportWrapper || !supportCards) return;

  const springBubble = springer(0.5, 0.65);

  
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: supportWrapper,
      start: "top 80%",
      scrub: false,
    },
  });

  tl.fromTo(
    supportCards,
    {
      opacity: 0,
      scale: 0.85,
    },
    {
      opacity: 1,
      scale: 1,
      duration: 1.5,
      stagger: {
        amount: 0.4,
      },
      ease: springBubble,
    }
  );
}
