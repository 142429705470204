// loadScript.js
export function loadScript(type, url) {
  return new Promise((resolve, reject) => {
    let element;

    if (type === "css") {
      element = document.createElement("link");
      element.rel = "stylesheet";
      element.href = url;
    } else if (type === "js") {
      element = document.createElement("script");
      element.src = url;
      element.async = true;
    } else {
      reject(
        new Error('Invalid script type. Only "css" and "js" are allowed.')
      );
    }

    element.onload = () => resolve();
    element.onerror = () =>
      reject(new Error(`Error loading ${type} file: ${url}`));

    document.head.appendChild(element);
  });
}

export function loadScriptWhenIdle(type, src) {
  return new Promise((resolve, reject) => {
    if ("requestIdleCallback" in window) {
      requestIdleCallback(
        () => {
          loadScript(type, src).then(resolve).catch(reject);
        },
        { timeout: 2000 }
      );
    } else {
      // Fallback to setTimeout for browsers that don't support requestIdleCallback
      setTimeout(() => {
        loadScript(type, src).then(resolve).catch(reject);
      }, 0);
    }
  });
}
