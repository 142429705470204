function testUserAgent(regex) {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return regex.test(userAgent);
}

export function isMobile() {
  const mobileRegex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return testUserAgent(mobileRegex);
}

export function isTablet() {
  const tabletRegex = /iPad|Android(?!.*Mobile)|Tablet|PlayBook|Silk|kindle/i;
  return testUserAgent(tabletRegex);
}

export function isAndroid() {
  const androidRegex = /Android/i;
  return testUserAgent(androidRegex);
}

export function isIOS() {
  const iosRegex = /iPad|iPhone|iPod/i;
  return testUserAgent(iosRegex);
}
