import springer from "springer";

export function marqueeAppear() {
  const marqueeContainer = document.querySelector(".container-anim---header");

  if (!marqueeContainer) return;

  const cards = document.querySelectorAll(".wrapper-hero img");

  const springBubble = springer(0.4, 0.7);
  const springRotate = springer(0.3, 0.4);

  const tl = gsap.timeline();

  cards.forEach((card, index) => {
    const yOffset = index % 2 === 0 ? 100 : -100;
    tl.fromTo(
      card,
      { opacity: 0, y: yOffset },
      { opacity: 1, y: 0, duration: 2, ease: springBubble, delay: '-0.3' },
      index * 0.04
    );
  });

  tl.fromTo(
    marqueeContainer,
    { rotate: 0 },
    { rotate: "-10deg", duration: 3, ease: springRotate, delay: "-2.7" }
  ).fromTo(
    document.querySelector(".container-anim---header-sketch"),
    { rotate: 0, opacity: 0 },
    {
      rotate: "10deg",
      opacity: 1,
      duration: 3,
      ease: springRotate,
      delay: "-3",
    }
  );
}
