let isElementVisible = false;

function handleVisibility(isVisible, element) {
  element.style.filter = isVisible ? "invert(1)" : "invert(0)";
  isElementVisible = isVisible;
}

export function burger() {
  const button = document.querySelector('a.little-secondary-cta');
  const buttons = document.querySelectorAll('.invert-button');
  const burger = document.querySelector(".menu-button-2");
  const sections = document.querySelectorAll(
      '[class*="--section"]:not([class*="price-hero"]), [class*="wrapper-"]:not([class*="price-hero"]), [class*="hero-"]:not([class*="price-hero"]), [no-bg-color="true"]:not([class*="price-hero"]), .div-thank-you:not([class*="price-hero"])'
  );
  const link = document.querySelector('a[href="/fr/essayer-gratuitement"]') || null;

  if (!burger || !button) return;

  const blackSections = Array.from(sections).filter((section) => {
    const styles = window.getComputedStyle(section);
    const backgroundColor = styles.getPropertyValue("background-color");
    return (
      backgroundColor === "rgb(0, 0, 0)" ||
      [
        document.querySelector(".wrapper-slider"),
        document.querySelector("[no-bg-color='true']"),
        document.querySelector(".div-thank-you"),
      ].includes(section)
    );
  });

  blackSections?.forEach((section) => {
    gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top-=20px top",
        end: "bottom-=20px top",
        scrub: false,
        onEnter: () => {
          handleVisibility(true, burger);
          handleVisibility(true, button);
          buttons.forEach(button => handleVisibility(true, button));
          if(link) link.style.border = "1px solid white";
        },
        onEnterBack: () => {
          handleVisibility(true, burger);
          handleVisibility(true, button);
          buttons.forEach(button => handleVisibility(true, button));
          if(link) link.style.border = "1px solid white";
        },
        onLeave: () => {
          handleVisibility(false, burger);
          handleVisibility(false, button);
          buttons.forEach(button => handleVisibility(false, button));
          if(link) link.style.border = "1px solid black";
        },
        onLeaveBack: () => {
          handleVisibility(false, burger);
          handleVisibility(false, button);
          buttons.forEach(button => handleVisibility(false, button));
          if(link) link.style.border = "1px solid black";
        },
      },
    });
  })

  const betterBlackSections = document.querySelectorAll('.black-section');

  betterBlackSections?.forEach((section) => {
    gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top-=20px top",
        end: "bottom-=20px top",
        scrub: false,
        onEnter: () => {
          handleVisibility(true, burger);
          handleVisibility(true, button);
          buttons.forEach(button => handleVisibility(true, button));
        },
        onEnterBack: () => {
          handleVisibility(true, burger);
          handleVisibility(true, button);
          buttons.forEach(button => handleVisibility(true, button));
        },
        onLeave: () => {
          handleVisibility(false, burger);
          handleVisibility(false, button);
          buttons.forEach(button => handleVisibility(false, button));
        },
        onLeaveBack: () => {
          handleVisibility(false, burger);
          handleVisibility(false, button);
          buttons.forEach(button => handleVisibility(false, button));
        },
      },
    });
  });

  burger?.addEventListener("click", () => {
    burger.style.filter = "invert(0)";
    if (burger.classList.contains("w--open") && isElementVisible) {
      setTimeout(() => {
        burger.style.filter = "invert(1)";
      }, 200);
    }
  });
}