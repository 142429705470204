import springer from "springer";
import { isMobile } from "../libs/devices";

export function textImage() {
  const texts = document.querySelectorAll(
    ".wrapper-texte---text-image .w-richtext, .wrapper-texte---text-image .body---book, .grid---image-text-module .body---book"
  );
  const ctas = document.querySelectorAll(".wrapper-texte---text-image a");
  const containerTextImage = document.querySelectorAll(
    ".wrapper---texte-image, .grid---image-text-module"
  );

  if (texts.length === 0 || containerTextImage === 0) return;

  // Filter elements excluding those with a parent that is a hero

  const filteredContainers = Array.from(containerTextImage).filter(
    (element) => {
      const parent = element.parentElement;
      return parent.getAttribute("hero-mode") !== "true";
    }
  );

  // Animation of textImage container
  const springBubble = springer(1, 0.5);

  filteredContainers.forEach((container) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        start: isMobile() ? "100px bottom" : "300px bottom",
        scrub: false,
      },
    });

    tl.fromTo(
      container.querySelector(".wrapper-texte---text-image"),
      {
        x: 5,
        y: 10,
        scale: 0.95,
      },
      {
        x: 0,
        y: 0,
        scale: 1,
        duration: 1.5,
        ease: springBubble,
      }
    )
      .fromTo(
        container.querySelector(".h-30px---serif"),
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1.5,
          ease: springBubble,
        },
        "-=1.5"
      )
      .fromTo(
        container.querySelectorAll(".w-richtext, .body---book"),
        {
          opacity: 0,
          y: 20,
        },
        {
          opacity: 1,
          y: 0,
          stagger: {
            each: 0.05,
          },
          duration: 1.5,
          ease: springBubble,
        },
        "-=1.45"
      )
      .fromTo(
        container.querySelector(
          ".content-image---text-image img, .div-block-44"
        ),
        {
          opacity: 0,
          x: 5,
          y: 10,
        },
        {
          opacity: 1,
          x: 0,
          y: 0,
          duration: 1.5,
          ease: springBubble,
        },
        "-=1.5"
      )
      .fromTo(
        container.querySelector(".primary-cta-wrapper") ||
          container.querySelector("a"),
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1.5,
          ease: springBubble,
        },
        "-=1.4"
      );
  });
}

// Determine width of a text
function getTextWidth(text, font) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  const width = context.measureText(text).width;
  canvas.remove();
  return width;
}
