import { EasingFunctions } from "../libs/easings.js";
import { throttle } from "../libs/throttle.js";

export class Marquee {
  constructor(element, options = {}) {
    this.element = element;
    this.duration = options.duration || 10;
    this.direction = options.direction || "left";
    this.gap = options.gap || 20;
    this.clones = options.clones || 8;
    this.masterTimeline = null;
    this.isMouseHover = false;
    this.stopOnHover = options.stopOnHover || false;
    this.init();
  }

  init() {
    const elementWidth = this.getElementWidth();
    const width = this.calculateWidth(elementWidth);
    this.cloneDivs(this.clones);
    this.createMasterTimeline(width);

    if (this.stopOnHover && window.innerWidth >= 991) {
      this.setupHoverListeners();
    }

    this.activateMarqueeOnScroll();
    this.activateMarquee();
  }

  activateMarqueeOnScroll() {
    window.addEventListener(
      "scroll",
      throttle(() => {
        if (!this.isMouseHover) {
          this.activateMarquee();
        }
      }, 100)
    );
  }

  activateMarquee() {
    this.masterTimeline.play();
  }

  setupHoverListeners() {
    this.hoverDuration = 1000;
    if (this.isTouchDevice()) {
      this.element.addEventListener("touchstart", this.onTouchStart);
    } else {
      this.element.addEventListener("mouseenter", this.onEnter);
      this.element.addEventListener("mouseleave", this.onLeave);
    }
  }

  onTouchStart = () => {
    this.isMouseHover ? this.onLeave() : this.onEnter();
  };

  onLeave = () => {
    this.isMouseHover = false;
    this.resumeMarquee();
  };

  onEnter = () => {
    this.isMouseHover = true;
    this.pauseMarquee();
  };

  pauseMarquee() {
    gsap.to(this.masterTimeline, {
      timeScale: 0,
      duration: 0.5,
    });
  }

  resumeMarquee() {
    gsap.to(this.masterTimeline, {
      timeScale: 1,
      duration: 0.5,
    });
  }

  isTouchDevice() {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  }

  getElementWidth() {
    return this.element.querySelector("div").getBoundingClientRect().width;
  }

  calculateWidth(elementWidth) {
    return (this.clones - 1) * (elementWidth + this.gap);
  }

  createMasterTimeline(width) {
    this.masterTimeline = gsap.timeline({
      repeat: -1,
      defaults: {
        ease: "none",
        willChange: "transform",
        duration: this.duration,
        x: `${this.direction === "left" ? -width : width}px`,
      },
    });

    gsap.set(this.element, {
      gridColumnGap: this.gap,
      display: "flex",
    });

    this.masterTimeline.to(this.element, {
      x: this.direction === "left" ? -width : width,
      ease: "none",
    });
  }

  cloneDivs(count) {
    const fragment = document.createDocumentFragment();
    const div = this.element.querySelector("div");

    for (let i = 0; i < count; i++) {
      fragment.appendChild(div.cloneNode(true));
    }

    this.element.appendChild(fragment);
  }

  setupScrollTrigger() {
    const timeScaleClamp = gsap.utils.clamp(1, 6);

    ScrollTrigger.create({
      start: 0,
      end: "max",
      onUpdate: (self) => {
        if (!this.isTouchDevice()) {
          const velocity = Math.abs(self.getVelocity() / 100);
          this.masterTimeline.timeScale(timeScaleClamp(velocity));
        }
      },
    });
  }
}
