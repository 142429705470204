import springer from "springer";

export function listItem() {
  const listItems = document.querySelectorAll(".list-items---section");
  if (listItems.length === 0) return;

  listItems.forEach((listEach) => {
    const listItemsArray = Array.from(
      listEach.querySelectorAll(".row-list-items")
    );

    const tlNoScrub = gsap.timeline({
      scrollTrigger: {
        trigger: listItems,
        start: "center bottom",
        end: "bottom top",
        once: true,
      },
    });

    const springBubble = springer(0.5, 0.65);

    tlNoScrub.add(
      gsap.fromTo(
        listItemsArray,
        { opacity: 0, scale: 0.85, y: 50 },
        {
          opacity: 1,
          scale: 1,
          stagger: 0.1,
          y: 0,
          ease: springBubble,
          duration: 1,
        }
      ),
      0
    );
  });
}
