//letsignit-en.webflow.io
import { loadScript } from "../../libs/loader.js";
export async function googletagmanager() {
  let en = document.querySelector("[data-wf-domain='letsignit-en.webflow.io']");
  const delayTime = 0;

  if (en) {
    // EN
    window.setTimeout(async () => {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-WMZ5FGS");

      await loadScript(
        "js",
        "https://www.googletagmanager.com/gtag/js?id=AW-644760122"
      );

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "AW-644760122");
    }, delayTime);
  } else {
    window.setTimeout(async () => {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-PNJ77P6");

      await loadScript(
        "js",
        "https://www.googletagmanager.com/gtag/js?id=AW-967737157"
      );

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "AW-967737157");
    }, delayTime);
  }
}
