export function summary() {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;

  const titles = document.querySelectorAll(
    ".article-container h2, .article-container-2 h2, .article-container-3 h2"
  );
  const wrapperTable = document.querySelector(".wrapper-table");
  if (!titles || !wrapperTable) return;
  wrapperTable.innerHTML = "";

  titles.forEach((titleElement, index) => {
    const id = titleElement.textContent
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/g, "")
      .replace(/\s+/g, "-");

    titleElement.id = id;

    const label = document.createElement("div");
    const titleText = titleElement.textContent.trim();

    label.textContent = titleText;

    label.classList.add("label-table");

    const a = document.createElement("a");
    a.href = `#${id}`;
    a.classList.add("tag-table", "w-inline-block");
    a.appendChild(label);
    wrapperTable.appendChild(a);

    gsap.timeline({
      scrollTrigger: {
        trigger: titleElement,
        start: screenWidth <= 766 ? "120px bottom" : "-101px top", // Ajustement de la valeur de start
        end: "bottom top",
        scrub: false,

        onEnter: () => {
          document
            .querySelectorAll(".wrapper-table a")
            .forEach((link) => link.classList.remove("active"));
          a.classList.add("active");
        },
        onLeaveBack: () => {
          a.classList.remove("active");
          if (index > 0)
            wrapperTable.childNodes[index - 1].classList.add("active");
        },
      },
    });
  });

  if (screenWidth <= 766) {
    const wrapperTableMenuContainer = document.createElement("div");
    wrapperTableMenuContainer.classList.add("wrapper-table-menu-container");

    const wrapperTableMenu = document.createElement("div");
    wrapperTableMenu.classList.add("wrapper-table-menu");

    titles.forEach((titleElement, index) => {
      const titleText = titleElement.textContent.trim();

      const menuItem = document.createElement("a");
      menuItem.href = `#${titleElement.id}`;
      menuItem.classList.add("wrapper-table-menu-item");
      menuItem.textContent = titleText;

      wrapperTableMenuContainer.appendChild(menuItem);

        gsap.timeline({
            scrollTrigger: {
                trigger: titleElement,
                start: screenWidth <= 766 ? "120px bottom" : "-101px top", // Ajustement de la valeur de start
                end: "bottom top",
                scrub: false,

                onEnter: () => {
                    document
                        .querySelectorAll(".wrapper-table-menu-container a")
                        .forEach((link) => link.classList.remove("active"));
                    menuItem.classList.add("active");
                },
                onLeaveBack: () => {
                    menuItem.classList.remove("active");
                    if (index > 0)
                        wrapperTableMenuContainer.childNodes[index - 1].classList.add("active");
                },
            },
        });

    });

    wrapperTableMenu.appendChild(wrapperTableMenuContainer);
    wrapperTable.insertAdjacentElement("beforebegin", wrapperTableMenu);

    const tagTable = document.querySelectorAll('.tag-table');
    tagTable.forEach((element) => {
      element.setAttribute('href', '#summary');

      element.addEventListener("click", (event) => {
        event.preventDefault();

        const wrapperTableMenu = document.querySelector(".wrapper-table-menu");
        if (wrapperTableMenu) {
          wrapperTableMenu.classList.toggle("show");
          wrapperTable.classList.toggle("hide");
        }
      });
    });

    const menuItems = document.querySelectorAll('.wrapper-table-menu-item')

    menuItems.forEach((element) => {
      element.addEventListener("click", () => {
        document
            .querySelectorAll(".wrapper-table-menu-container a")
            .forEach((link) => link.classList.remove("active"));
        element.classList.add("active");
        wrapperTableMenu.classList.remove("show");
        wrapperTable.classList.remove("hide");
      })
    })

  }
}
