export function inputSearch() {
  let element = document.querySelector(
    "[data-wf-domain='letsignit-fr.webflow.io']"
  );

  const searchBar = document.querySelector("#search");

  if (element && searchBar) {
    const dataFormFr = searchBar.parentNode;

    if (dataFormFr) {
      dataFormFr.addEventListener("submit", (e) => {
        e.preventDefault();

        const params = new URLSearchParams();
        for (let i = 0; i < e.target.elements.length; i++) {
          if (e.target.elements[i].type !== "submit") {
            params.append(
              e.target.elements[i].name,
              e.target.elements[i].value
            );
          }
        }

        const url = new URL("https://letsignit.com/fr/search");
        url.search = params;

        window.location.href = url.toString();
      });
    }
  }
}
