function wrap(el, wrapper) {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.classList.add("primary-cta-wrapper");
  wrapper.style.overflow = "hidden";
  wrapper.style.position = "relative";
  wrapper.style.display = "inline-block";
  wrapper.appendChild(el);
  return wrapper;
}

function createCircle() {
  const circle = document.createElement("span");
  circle.classList.add("primary-cta_circle");
  return circle;
}

export function primaryCtaDom() {
  const primaryCta = document.querySelectorAll(".primary-cta");
  if (!primaryCta) return;
  // Mouse enter
  primaryCta.forEach((cta) => {
    const btnWrapper = wrap(cta, document.createElement("div"));
    const circle = createCircle();
    btnWrapper.prepend(circle);

    const primaryColorBg = window.getComputedStyle(cta).backgroundColor;
    const primaryBorderColor = window.getComputedStyle(cta).borderColor;
    const hasBorder = cta.classList.contains("black");

    cta.style.backgroundColor = "transparent";
    cta.style.marginBottom = 0;
    cta.style.borderColor = "transparent";

    btnWrapper.style.backgroundColor = primaryColorBg;
    if (hasBorder) btnWrapper.style.border = `1px solid ${primaryBorderColor}`;
    btnWrapper.style.borderRadius = "25px";
  });
}
