export function curit() {
  const curit = document.querySelector(".s-curit---section");
  if (!curit) return;
  const curitCards = curit.querySelectorAll(".card---s-curit");
  const tlNoScrub = gsap.timeline({
    scrollTrigger: {
      trigger: curit,
      start: "center bottom",
      end: "bottom top",
      once: true,
    },
  });
  tlNoScrub.add(
    gsap.fromTo(
      curitCards,
      { opacity: 0, scale: 0.75, y: 100 },
      {
        opacity: 1,
        scale: 1,
        stagger: 0.1,
        y: 0,
        ease: "power4.out",
        duration: 1,
      }
    ),
    0
  );
  tlNoScrub.add(
    gsap.fromTo(
      curit.querySelector(".primary-cta-wrapper"),
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        stagger: 0.1,
        y: 0,
        ease: "power4.out",
        duration: 1,
      }
    ),
    0
  );
}
