import { animateNumber } from "../../animations/calculator";

export function calculator() {
  if (!document.querySelector(".input-key-figures")) return;

  const numKey = document.querySelectorAll(".numbers-key-figures");
  const [mailsSentEl, , potentialClicksEl] = numKey; // second element is a static value (potential brand visibility)

  const collabInput = document.querySelector(".input-key-figures");

  window.addEventListener("load", function () {
    collabInput.focus({
      preventScroll: true,
    });
  });

  // collabInput.value = 1000; // default value
  let nCollab = 1000;
  let previousMailsSent = 30 * 21 * nCollab; // default value
  let previousPotentialClicks = previousMailsSent * 0.03; // default value

  mailsSentEl.innerHTML = "630K";

  function updateCalculations() {
    const inputVal = Math.min(collabInput.value, 5000000);
    collabInput.value = inputVal;
    nCollab = inputVal;

    const mailsSent = 30 * 21 * nCollab; // (30 emails sent / day on average) x (21) nb working day x nb collab
    const potentialClicks = mailsSent * 0.03; // Number of emails (total of mailsSent) x 3% click-through rate

    animateNumber(mailsSentEl, previousMailsSent, mailsSent);
    animateNumber(potentialClicksEl, previousPotentialClicks, potentialClicks);

    previousMailsSent = mailsSent;
    previousPotentialClicks = potentialClicks;
  }

  // updateCalculations();

  collabInput.addEventListener("input", () => {
    updateCalculations();
  });

  collabInput.addEventListener("keypress", (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      collabInput.blur();
    }
  });

  collabInput.addEventListener("wheel", (e) => {
    if (collabInput.contains(e.target)) {
      collabInput.blur();
    }
  });

  collabInput.addEventListener("keydown", (e) => {
    if (
      collabInput.contains(e.target) &&
      (e.key === "ArrowUp" || e.key === "ArrowDown")
    ) {
      e.preventDefault();
      collabInput.blur();
    }
  });
}