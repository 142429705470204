import { animateNumber } from "./calculator";

export function keyNumbers() {
  const wrapperKeyNumbers = document.querySelector(".grid---key-numbers");
  const keyNumbers = document.querySelectorAll(".anim-numbers");

  const keyNumbersVal = Array.from(keyNumbers).map((keyNumber) =>
    parseInt(keyNumber.innerHTML)
  );

  keyNumbers.forEach((keyNumber, index) => {
    keyNumber.innerHTML = "0";
    const keyNumberVal = keyNumbersVal[index];

    gsap.timeline({
      scrollTrigger: {
        trigger: wrapperKeyNumbers,
        start: "150px bottom",
        end: "bottom top",
        scrub: false,
        onEnter: () => animateNumber(keyNumber, 0, parseInt(keyNumberVal)),
      },
    });
  });
}
