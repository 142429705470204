export function intercom() {
  function loadIntercom() {
    return new Promise((resolve, reject) => {
      var APP_ID = "nnduejnk";

      window.intercomSettings = {
        api_base: "https://api-iam.eu.intercom.io",
        app_id: APP_ID,
      };

      var w = window;
      var ic = w.Intercom;

      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;

        function l() {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/" + APP_ID;
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
          s.onload = () => resolve();
          s.onerror = () => reject(new Error("Error loading intercom"));
        }
        l();
      }
    });
  }

  // Load Intercom when the user is idle
  const btn = document.createElement("div");
  btn.id = "intercom-btn";
  const loaderDiv = document.createElement("div");
  loaderDiv.classList.add("loading-intercom");
  btn.appendChild(loaderDiv);
  const spinner = document.createElement("div");
  spinner.classList.add("spin1");
  loaderDiv.appendChild(spinner);

  document.body.appendChild(btn);

  window.setTimeout(() => {
    btn.classList.add("loaded");
  }, 100);

  btn.addEventListener("click", async () => {
    loaderDiv.classList.add("show");
    await loadIntercom();
    Intercom("show");
    window.setTimeout(() => {
      loaderDiv.classList.remove("show");
    }, 2000);
  });

  if (document.querySelector(".wrapper---faq .primary-cta-wrapper")) {
    document
      .querySelector(".wrapper---faq .primary-cta-wrapper")
      .addEventListener("click", async (e) => {
        e.preventDefault() && e.stopPropagation();
        if (window.hasOwnProperty("Intercom")) {
          Intercom("show");
        }
        loaderDiv.classList.add("show");
        await loadIntercom();
        Intercom("show");
        window.setTimeout(() => {
          loaderDiv.classList.remove("show");
        }, 2000);
      });
  }
}
