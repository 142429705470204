export function checkList() {
  const checkListUl = document.querySelectorAll(".article-right-content ul");

  checkListUl.forEach((ul) => {
    ul.classList.add("check-list-container");

    const checkListLi = ul.querySelectorAll("li");

    checkListLi.forEach((li) => {
      li.classList.add("check-list-item");
      const divIcon = document.createElement("div");
      divIcon.className = "check-list-icon";

      const divContent = document.createElement("div");
      divContent.className = "check-list-content";

      const childNodes = Array.from(li.childNodes);
      childNodes.forEach((node) => {
        const clonedNode = node.cloneNode(true);
        if (node.nodeType === Node.TEXT_NODE) {
          clonedNode.textContent = node.textContent;
        }
        divContent.appendChild(clonedNode);
      });

      li.innerHTML = "";
      li.appendChild(divIcon);
      li.appendChild(divContent);
    });
  });
}
