import springer from "springer";

export function pushVideo() {
  const pushVideoSection = document.querySelector(
    ".push-video---section .container---1200px:nth-of-type(2)"
  );
  const pushVideoCards = document.querySelectorAll(".card---push-video");

  if (!pushVideoSection || !pushVideoCards) return;
  const springCards = springer(0.3, 0.7);

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: pushVideoSection,
      start: "center bottom",
      end: "bottom top",
      once: true,
    },
  });

  tl.add(
    gsap.fromTo(
      pushVideoCards,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        stagger: 0.1,
        y: 0,
        duration: 1.5,
        ease: springCards,
      }
    )
  ).fromTo(
    document.querySelectorAll(".player---push-video, .top-content-card---push-video"),
    {
      opacity: 0,
      rotate: 10,
      scale: 0.90,
    },
    {
      opacity: 1,
      rotate: 0,
      scale: 1,
      stagger: 0.1,
      ease: springCards,
      duration: 1.2,
    }, '=-1.6'
  );
}
