import springer from "springer";
export function lsi() {
  const lsiSection = document.querySelector(".lsi-in---section");
  if (!lsiSection) return;

  //portraits
  const portraits = lsiSection.querySelectorAll(
    '[class^="image"], [class*=" image"]'
  );
  const springPortraits = springer(0.3, 0.4);
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: lsiSection,
      start: "center bottom",
      end: "bottom top",
      once: true,
      // scrub: true,
    },
  });
  tl.add(
    gsap.fromTo(
      portraits,
      {
        opacity: 0,
        scale: 0,
        rotate: 90 * Math.random() * (Math.random() > 0.5 ? 1 : -1),
      },
      {
        opacity: 1,
        scale: 1,
        rotate: 0,
        stagger: 0.1,
        ease: springPortraits,
        onStart: () => {
          lsiSection.style.pointerEvents = "none";
        },
        duration: 1,
        onComplete: () => {
          lsiSection.style.pointerEvents = "auto";
        },
      }
    ),
    0
  );

  // video
  const video = lsiSection.querySelector(".player-video");
  const springBubble = springer(0.3, 0.7);
  tl.add(
    gsap.fromTo(
      video,
      {
        opacity: 0,
        scale: 0,
      },
      {
        scale: 1,
        duration: 1.5,
        opacity: 1,
        ease: springBubble,
      }
    ),
    0
  );

  const bubble = lsiSection.querySelector(".rond-bg---lsi-in");
  tl.add(
    gsap.fromTo(
      bubble,
      {
        opacity: 0,
        scale: 0,
      },
      {
        scale: 1,
        opacity: 1,
        ease: springBubble,
        duration: 2,
      }
    ),
    0
  );

  const springVideo = springer(0.4, 0.7);
  // mouseenter
  video.addEventListener("mouseenter", () => {
    gsap.fromTo(
      video,
      {
        scale: 1,
      },
      {
        scale: 1.15,
        duration: 1,
        ease: springVideo,
      }
    );
    gsap.fromTo(
      bubble,
      {
        scale: 1,
      },
      {
        scale: 0.9,
        duration: 1,
        ease: springVideo,
      }
    );
  });
  // mouseleave
  video.addEventListener("mouseleave", () => {
    gsap.to(video, {
      scale: 1,
      duration: 1,
      ease: springVideo,
    });
    gsap.to(bubble, {
      scale: 1,
      duration: 1,
      ease: springVideo,
    });
  });
}
