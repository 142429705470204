export function switchLang() {
  const linkElements = document.querySelectorAll("link[hreflang]");
  const switchLangButton = document.querySelector(".language-footer a:not(.active)");

  if (!linkElements || !switchLangButton) return;

  const langLinks = {};
  const urls = {
    en: "https://letsignit.com",
    fr: "https://letsignit.com/fr",
  };

  linkElements.forEach((linkElement) => {
    const hrefValue = linkElement.getAttribute("href");
    const hreflangValue = linkElement.getAttribute("hreflang");
    const formattedHreflang = hreflangValue.split("-")[0];

    langLinks[formattedHreflang] = {
      href: hrefValue,
    };
  });

  const langText = switchLangButton.querySelector(".small-text---book");
  const formattedLanguage = langText.textContent.trim().toLowerCase().split("-")[0];

  const mismatchedURL = urls[formattedLanguage];

  const hrefValue = langLinks[formattedLanguage]?.href || "";

  const currentURL = new URL(window.location.href);
  let parentPath = currentURL.pathname.split("/").slice(0, -1).join("/");

  const pagesNoTrad = ["lp-webinar", "typ", "podcast"];

  if (pagesNoTrad.some(page => currentURL.href.includes(page))) {
    parentPath = "";
  }

  const switchHref = hrefValue === "" ? mismatchedURL + parentPath : hrefValue;

  switchLangButton.setAttribute("href", switchHref);
}
