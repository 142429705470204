import springer from "springer";

export function engagement() {
  const engagementSections = document.querySelectorAll(
    ".engagement---section-n"
  );

  if (!engagementSections) return;

  const springBubble = springer(0.5, 0.65);

  engagementSections.forEach((section) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "300px bottom",
        scrub: false,
      },
    });

    tl.fromTo(
      section.querySelector(".title-block---engagement"),
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        scale: 1,
        y: 0,
        ease: springBubble,
        duration: 1,
      }
    ).fromTo(
      section.querySelectorAll(".card---engagement"),
      { opacity: 0, scale: 0.85, y: 50 },
      {
        opacity: 1,
        scale: 1,
        stagger: 0.1,
        y: 0,
        ease: springBubble,
        duration: 1,
      },
      "-=1"
    );
  });
}
