export async function calendly() {
  const thankYouParagraph = document.querySelector(".paragraph-thank-you");
  const thankYouDiv = document.querySelector(".div-thank-you");
  const columnsCalendly = document.querySelectorAll(".columns-calendly .w-col");

  if (!thankYouDiv || !thankYouParagraph || !columnsCalendly) return;

  for (const column of columnsCalendly) {
    const loader = document.createElement("div");
    loader.classList.add("loader");
    column.appendChild(loader);
  }

  const iframes = thankYouDiv.querySelectorAll("iframe");
  let loadedCount = 0;
  iframes.forEach((iframe) => {
    iframe.addEventListener("load", () => {
      loadedCount++;
      if (loadedCount === iframes.length) {
        document.querySelectorAll(".loader").forEach((loader) => {
          loader.remove();
        });
        document
          .querySelectorAll(".calendly-inline-widget")
          .forEach((widgetContainer) => {
            widgetContainer.style.display = "block";
          });
      }
    });
  });

  window.setTimeout(() => {
    // iframes.forEach((iframe) => {
    document.querySelectorAll(".loader").forEach((loader) => {
      loader.remove();
    });
    document
      .querySelectorAll(".calendly-inline-widget")
      .forEach((widgetContainer) => {
        widgetContainer.style.display = "block";
      });
    // });
  }, 3000);
}
