export function gob() {
  const gob = document.querySelector(".gob---section");
  if (!gob) return;

  const gobBefore = document.querySelector(".bg---gob-before");
  const signatureImgAfter = document.querySelector(".bg---gob .image-20");
  const signatureImgBefore = document.querySelector(".bg---gob-before .image-20");

  if (window.innerWidth <= 991) {
    const card = gsap.timeline({
      scrollTrigger: {
        trigger: gob,
        start: "45% bottom",
        end: "bottom top",
        scrub: true,
      },
    });

    card.to([signatureImgBefore, signatureImgAfter], {
      rotation: 10,
      duration: 0.3,
      ease: "power4.out",
    });

    gsap.timeline({
      scrollTrigger: {
        trigger: gob,
        start: "85% bottom",
        end: "bottom top",
        onEnter: () => {
          gsap.to(gobBefore, {
            opacity: 0,
            duration: 0.5,
            ease: "power4.out",
          });
        },
        onLeaveBack: () => {
          gsap.to(gobBefore, {
            opacity: 1,
            duration: 0.5,
            ease: "power4.out",
          });
        },
      },
    });

    return;
  }

  let isColorChanged = false;

  gsap.timeline({
    scrollTrigger: {
      trigger: gob,
      start: "45% bottom",
      end: "bottom top",
      scrub: true,
    },
  });

  function handleMouseMove(event) {
    if (!gob.contains(event.target)) {
      return;
    }

    const screenWidth = window.innerWidth;
    const cursorPositionX = event.clientX;
    const percentage = (cursorPositionX / screenWidth) * 72 - 36;

    const opacity = percentage > 0 ? 0 : 1;

    if (isColorChanged !== percentage > 0) {
      isColorChanged = percentage > 0;

      gsap.to(gobBefore, {
        opacity: opacity,
        duration: 0.5,
        ease: "power4.out",
      });
    }

    gsap.to([signatureImgBefore, signatureImgAfter], {
      rotation: percentage / 3,
      duration: 0.2,
      ease: "power4.out",
    });
  }

  ScrollTrigger.create({
    trigger: gob,
    start: "top bottom",
    end: "bottom top",
    onEnter: () => {
      document.addEventListener("mousemove", handleMouseMove);
    },
    onLeaveBack: () => {
      document.removeEventListener("mousemove", handleMouseMove);
    },
  });
}
