import springer from "springer";

export function keyFigures() {
  const keyFiguresContainer = document.querySelectorAll(
    ".container---key-figures"
  );

  if (!keyFiguresContainer) return;

  const springBubble = springer(1, 0.5);

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: keyFiguresContainer,
      start: "top bottom",
      scrub: false,
    },
  });

  tl.fromTo(
    keyFiguresContainer,
    {
      opacity: 0,
      y: 100,
      scale: 0.9,
    },
    {
      opacity: 1,
      y: 0,
      scale: 1,
      duration: 1.5,
      ease: springBubble,
    }
  );
}
