import springer from "springer";
import { isMobile } from "../libs/devices";
import { throttle } from "../libs/throttle";


export function gridImages() {
  const gridImages = document.querySelectorAll(".grid-images");
  if (!gridImages.length) return;

  gridImages.forEach((grid) => {
    grid.style.transform = isMobile()
      ? "translateX(10%) translateY(-10%) rotate(-30deg)"
      : "translateX(5%) translateY(-20%) rotate(-30deg)";

    const images = Array.from(grid.querySelectorAll("img"));
    const gridWrapper = grid.parentNode;
    gridWrapper.classList.add("grid-images---wrapper");

    const { top: getPosTop } = gridWrapper.getBoundingClientRect();
    const winHeight = window.innerHeight;

    // Fonction de scroll
    function handleScroll() {
      const scrollPos = window.scrollY;
      const scrollPosTop = scrollPos + winHeight * 2;

      if (scrollPosTop > getPosTop) {
        images.forEach((image) => {
          if (!image.hasAttribute("data-loaded")) {
            const img = new Image();
            img.src = image.src;
            img.onload = () => {
              image.setAttribute("data-loaded", true);
            };
          }
        });

        gridWrapper.classList.add("grid-images---wrapper--loaded");
        window.removeEventListener("scroll", throttledScroll);
      }
    }

    const throttledScroll = throttle(() => {
      requestAnimationFrame(handleScroll);
    }, 100); // Réduction du throttling à 100ms pour plus de réactivité

    window.addEventListener("scroll", throttledScroll);

    // scrollTrigger Grid
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: gridWrapper,
        start: "top bottom",
        end: "bottom bottom",
        scrub: true,
        preventOverlaps: true,
        fastScrollEnd: true,
      },
    });

    const tlNoScrub = gsap.timeline({
      scrollTrigger: {
        trigger: ".grid-images---wrapper",
        start: "center bottom",
        end: "bottom center",
      },
    });

    images.forEach((image, index) => {
      const distance = [125, 75, 150, 100][index] || 100;

      tl.add(
        gsap.fromTo(
          image,
          {
            y: `-${distance}px`,
            opacity: 0,
            scale: 0.9,
            willChange: "transform, opacity",
          },
          {
            y: `${distance}px`,
            opacity: 1,
            scale: 1,
            ease: 'none'
          }
        ),
        0
      );
    });

    const springBubble = springer(0.3, 0.7);
    const block = gridWrapper.querySelector(".wrapper---cta");

    tlNoScrub.add(
      gsap.fromTo(
        block,
        {
          scale: 0.8,
          opacity: 0,
        },
        {
          y: "0",
          scale: 1,
          opacity: 1,
          ease: springBubble,
          duration: 2,
          force3D: true,
        }
      ),
      0.25
    );

    tlNoScrub.add(
      gsap.fromTo(
        [
          block.querySelector(".primary-cta"),
          block.querySelector(".h-30px---serif"),
          block.querySelector(".body---book"),
        ],
        {
          scale: 0.8,
          opacity: 0,
        },
        {
          y: "0",
          scale: 1,
          opacity: 1,
          force3D: true,
          ease: springBubble,
          duration: 1,
          stagger: 0.1,
        }
      ),
      0.5
    );
  });
}
