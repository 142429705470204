import springer from "springer";

export function videoPopup() {
  if (!document.querySelectorAll(".card---push-video")) return;

  const pushVideoWrappers = document.querySelectorAll(".top-content-card---push-video");
  const popupWrapper = document.createElement("div");
  popupWrapper.classList.add("popup-wrapper");
  popupWrapper.innerHTML = `
    <div class="popup-inner">
        <div class="popup-close">
          <div class="popup-close-icon w-embed">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14"><path d="m1.61.21.1.08L7 5.6l5.3-5.3a1 1 0 0 1 1.49 1.32l-.08.1L8.4 7l5.3 5.3a1 1 0 0 1-1.32 1.49l-.1-.08L7 8.4l-5.3 5.3a1 1 0 0 1-1.49-1.32l.08-.1L5.6 7 .29 1.7A1 1 0 0 1 1.61.22Z" fill="currentColor" fill-rule="nonzero"></path></svg>
          </div>
        </div>
        <div class="popup-content-wrapper video">
          <div class="popup-content has-video"></div>
        </div>
        <div class="popup-background"></div>
    </div>
  `;
  pushVideoWrappers.forEach((pushVideoWrapper) => {
    const player = pushVideoWrapper.querySelector(".player---push-video");
    pushVideoWrapper.appendChild(popupWrapper.cloneNode(true));

    const springVideo = springer(0.4, 0.7);

    // mouseenter
    pushVideoWrapper.addEventListener("mouseenter", () => {
      gsap.fromTo(
        pushVideoWrapper,
        {
          scale: 1,
        },
        {
          scale: 0.9,
          duration: 1,
          ease: springVideo,
        }
      );
      gsap.fromTo(
        player,
        {
          scale: 1,
        },
        {
          scale: 1.5,
          duration: 1,
          ease: springVideo,
        }
      );
    });

    // mouseleave
    pushVideoWrapper.addEventListener("mouseleave", () => {
      gsap.to(pushVideoWrapper, {
        scale: 1,
        duration: 1,
        ease: springVideo,
      });
      gsap.to(player, {
        scale: 1,
        duration: 1,
        ease: springVideo,
      });
    });
  });
}
