const minusSvg = `<svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 1H10" stroke="black"/>
</svg>`;
const plusSvg = `<svg width=" 100%" height=" 100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.3334 15.6668V16.3334C25.3334 16.7016 25.0349 17.0001 24.6667 17.0001H17.0001V24.6667C17.0001 25.0349 16.7016 25.3334 16.3334 25.3334H15.6667C15.2986 25.3334 15.0001 25.0349 15.0001 24.6667L15.0001 17.0001H7.33341C6.96522 17.0001 6.66675 16.7016 6.66675 16.3334V15.6668C6.66675 15.2986 6.96522 15.0001 7.33341 15.0001H15.0001V7.33341C15.0001 6.96522 15.2986 6.66675 15.6667 6.66675H16.3334C16.7016 6.66675 17.0001 6.96522 17.0001 7.33341V15.0001L24.6667 15.0001C25.0349 15.0001 25.3334 15.2986 25.3334 15.6668Z" fill="currentColor"></path>
</svg>`;

export function faq() {
  const faqs = document.querySelectorAll(".row-faq");

  const rows = document.querySelectorAll(".right-content-row-faq");

  const responses = document.querySelectorAll(".rl_faq6_answer");

  const iconsWrapper = document.querySelectorAll(".rl_faq6_icon-wrapper");

  // Default values
  responses.forEach((response) => {
    response.style.height = "0";
  });
  iconsWrapper.forEach((iconWrapper) => {
    iconWrapper.style.display = "flex";
  });

  rows.forEach((row) => {
    const borderDiv = document.createElement("div");
    borderDiv.classList.add("border-faq");
    row.appendChild(borderDiv);
  });

  let marginLeft;
  window.matchMedia("(min-width: 992px)").matches
    ? (marginLeft = 100)
    : (marginLeft = 0);

  // Check if faqs exist on DOM
  if (faqs) {
    // Add click event to each faq
    faqs.forEach((faq) => {
      faq.querySelector(".top-row---faq").addEventListener(
        "click",
        (event) => {
          event.stopPropagation();
          const currentTarget = event.currentTarget.parentNode.parentNode; // select .row-faq current target

          // Close all other active faqs
          const actives = document.querySelectorAll(".row-faq.active");
          // Check if faq is already active
          if (currentTarget.classList.contains("active")) {
            // Toggle active class
            currentTarget.classList.toggle("active");
            currentTarget.querySelector(".rl_faq6_icon").innerHTML = plusSvg;

            const tl = gsap.timeline();

            tl.to(currentTarget.querySelector(".rl_faq6_answer"), {
              height: 0,
              duration: 0.5,
              ease: "power4.out",
            }).to(
              currentTarget.querySelector(".border-faq"),
              {
                marginLeft: 0,
                background: "rgba(0, 0, 0, .5)",
                duration: 0.5,
                ease: "power4.out",
              },
              "-=0.5"
            );
          } else {
            // Toggle active class
            currentTarget.classList.toggle("active");
            // Toggle plus/minus svg
            currentTarget.querySelector(".rl_faq6_icon").innerHTML = minusSvg;
            // Animate height of faq answer

            const tl = gsap.timeline();

            tl.fromTo(
              currentTarget.querySelector(".rl_faq6_answer"),
              {
                height: 0,
              },
              {
                height: "auto",
                duration: 0.5,
                ease: "power4.out",
              }
            ).to(
              currentTarget.querySelector(".border-faq"),
              {
                marginLeft: marginLeft,
                background: "black",
                duration: 0.5,
                ease: "power4.out",
              },
              "-=0.5"
            );
          }
          actives.forEach((active) => {
            if (active !== faq) {
              active.classList.remove("active");
              active.querySelector(".rl_faq6_icon").innerHTML = plusSvg;
              const tl = gsap.timeline();
              tl.to(active.querySelector(".rl_faq6_answer"), {
                height: 0,
                duration: 0.35,
                ease: "power4.out",
              }).to(
                active.querySelector(".border-faq"),
                {
                  marginLeft: 0,
                  background: "rgba(0, 0, 0, .5)",
                  duration: 0.34,
                  ease: "power4.out",
                },
                "-=0.34"
              );
            }
          });
        },
        false
      );
    });
  }
}
