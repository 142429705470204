import { loadScript } from "../../libs/loader.js";
export async function marketo(marketoId, formId) {

  if (!document.querySelector(".container-form")) return;

  const loader = document.createElement("div");
  loader.classList.add("loader");
  const containerForm = document.querySelector(".container-form");
  const embed = document.querySelector(".w-embed");
  containerForm.appendChild(loader);

  await loadScript("js", "//go.letsignit.fr/js/forms2/js/forms2.min.js");
  const invalidEmailDomains = [
    "@gmail.",
    "@yopmail.",
    "@abc.",
    "@yahoo.",
    "@hotmail.",
    "@live.",
    "@aol.",
    "@outlook.",
    "@orange.",
    "@free.",
    "@online.",
    "@wanadoo.",
    "@S3.guardianmail.xyz",
    "@tab.buzzcluby.com",
    "@burjanet.ru",
    "@lolusa.ru",
    "@usalol.ru",
    "@goooogle.usa.cc",
    "@straycare.org",
    "@bongobongo.flu.cc",
    "@banglatvshow.com",
    "@sfr.fr",
    "@mrresourcepacks.tk",
    "@sheep.citetick.com",
    "@laposte.net",
    "@muehlacker.tk",
    "@cegetel.net",
    "@yahoo.it",
    "@icloud.com",
    "@bbox.fr",
    "@vio.126.com",
    "@xy9ce.tk",
    "@spambog.com",
    "@serialfilms.ru",
    "@kmail.host",
    "@tab.buzzcluby.com",
    "@neuf.fr",
    "@gmx.fr",
    "@bbox.fr",
    "@mail.ru",
    "@kmail.host",
    "@mailcatch.com",
    "@*.pl",
    "@me.com",
    "@example.com",
  ];

  const errorFreemail = "Must be a professional email. example@yourdomain.com";

  let isEmailGood = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email.toLowerCase());
  };

  if (window.isEmailGood) {
    isEmailGood = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      return (
        emailRegex.test(email.toLowerCase()) &&
        !invalidEmailDomains.some((domain) =>
          email.toLowerCase().includes(domain)
        )
      );
    };
  }

  const phoneNumberPattern =
    /^(?:(?:\+?([1-9]\d{1,2}))\s?)?[-. (]*(\d{1,4})[-. )]*(\d{1,4})[-. ]*(\d{1,4})(?:\s?(?:x|ext\.?|extension)\s?(\d+))?$/;

  const isTelephoneNumber = (value) => phoneNumberPattern.test(value);
  MktoForms2.whenRendered(async (form) => {
    // UTM TRACKER
    await loadScript(
      "js",
      "https://d12ue6f2329cfl.cloudfront.net/resources/utm_form-1.2.0.min.js"
    );
    var _uf = _uf || {};
    _uf.domain = "letsignit.com";
    _uf.cookieExpiryDays = 7;
    _uf.additional_params_map = {
      gclid: "IGCLID",
      fbclid: "IFBCLID",
      place: "IPLACE",
      net: "INET",
      match: "IMATCH",
    };
    _uf.secure = true;

  });

  MktoForms2.whenReady(async (form) => {
    // UTM TRACKER
    await loadScript(
      "js",
      "https://d12ue6f2329cfl.cloudfront.net/resources/utm_form-1.2.0.min.js"
    );
    var _uf = _uf || {};
    _uf.domain = "letsignit.com";
    _uf.cookieExpiryDays = 7;
    _uf.additional_params_map = {
      gclid: "IGCLID",
      fbclid: "IFBCLID",
      place: "IPLACE",
      net: "INET",
      match: "IMATCH",
    };
    _uf.secure = true;

    loader.style.display = "none";
    document.querySelector(".w-embed").style.display = "block";

    const formEl = form.getFormElem()[0];
    const emailEl = formEl.querySelector("input[name='Email']");
    const emailJq = MktoForms2.$(emailEl);

    const handleBlur = (event) => {
      const el = event.currentTarget;
      const parent = el.parentNode;
      const isPhone = el.classList.contains("mktoTelField");
      const isEmail = el.classList.contains("mktoEmailField");
      const isValid = isPhone
        ? isTelephoneNumber(el.value)
        : isEmail
        ? isEmailGood(el.value)
        : el.value.length >= 2;

      // to avoid overlapping

      if (el.id === "Tranche_effectif__c") {
        el.style.background = "none";
      }

      parent.style.background = `url('https://uploads-ssl.webflow.com/63c820b78b31aa26e9b62a00/643036d0c43d9978a39ce0ef_check.png') no-repeat right center`;
      if (!isValid) {
        parent.style.background = `url('https://uploads-ssl.webflow.com/63c820b78b31aa26e9b62a00/643036d34c985d4aafcbbc83_wrong.png') no-repeat right center`;

        // custom error message for not professional email
        window.isEmailGood &&
          isEmail &&
          invalidEmailDomains.some((domain) =>
            el.value.toLowerCase().includes(domain)
          ) &&
          form.showErrorMessage(errorFreemail, emailJq);
      }
    };

    // Auto label title with marketo placeholder

    function changeLabels() {
      const allLabels = document.querySelectorAll(".mktoFormRow label");

      const labels = Array.from(allLabels).map((labelEl) => {
        console.log(labelEl);
        const forAttr = labelEl.getAttribute("for");
        const inputEl = document.querySelector(`#${forAttr}`);
        const text = inputEl ? inputEl.placeholder || inputEl.value : "";
  
        return { forAttr, text };
      });
  
      labels.forEach((label) => {
        const { forAttr, text } = label;
        const labelEl = document.querySelector(`label[for='${forAttr}']`);
        if (!labelEl) return;
  
        if(labelEl.id === "LblDisclaimer_data_privacy__c") return;
        // if(labelEl.id === "LblTranche_effectif__c") return;
        labelEl.innerHTML = text;
  
        const textareaEl = document.querySelector(`#${forAttr}`);
        if (!textareaEl) return;
        textareaEl.placeholder = text;
      });
    }

    changeLabels();

    formEl.querySelectorAll("input").forEach((el) => {
      el.addEventListener("blur", handleBlur);
    });

    formEl.querySelectorAll("select").forEach((el) => {
      el.addEventListener("blur", handleBlur);
      el.addEventListener("change", changeLabels);
    });


  });
}
