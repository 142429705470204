import { isMobile } from "../../libs/devices";

export function horizontal() {
  const divParent = document.createElement("div");
  divParent.classList.add("horizontal-container");

  const title = document.createElement("p");
  title.classList.add("horizontal-container-title")
  const paragraph = document.createElement("p");

  const translations = {
    fr: {
      title: "Merci de tourner votre appareil",
      paragraph: "L'expérience du site fonctionne mieux en orientation portrait",
    },
    en: {
      title: "Please rotate your device",
      paragraph: "The website experience works best in portrait orientation",
    },
  };

  const language = window.location.href.includes("fr") ? "fr" : "en";
  const translation = translations[language];

  title.textContent = translation.title;
  paragraph.textContent = translation.paragraph;

  divParent.appendChild(title);
  divParent.appendChild(paragraph);
  document.body.appendChild(divParent);
}
