import { marqueeAppear } from "./marqueeAppear";
import { loadScript } from "../libs/loader.js";
import springer from "springer";

export async function homeHero() {
  const heroSection = document.querySelector(".hero---top-content");
  if (!heroSection) return;

  const title = document.querySelector(".title---hero");
  await loadScript(
    "js",
    "https://cdn.jsdelivr.net/npm/split-type@0.3.3/umd/index.min.js"
  );

  title.style.opacity = "1";

  let words = new SplitType([title], {
    types: "words",
    tagName: "span",
  });

  const springText = springer(5, 0.12);

  const tlWord = gsap.timeline();

  tlWord.fromTo(
    document.querySelectorAll(".wrapper---hero .word"),
    {
      opacity: 0,
      y: 15,
    },
    {
      opacity: 1,
      y: 0,
      duration: 0.8,
      stagger: {
        each: 0.045,
      },
      ease: springText,
    }
  );

  window.setTimeout(marqueeAppear(), 100);
}
