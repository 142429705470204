import springer from "springer";

export function resources() {
  const resoucesSection = document.querySelector(".re");

  if (!resoucesSection) return;

  const springBubble = springer(0.5, 0.65);

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: resoucesSection,
      start: "500px bottom",
    },
  });

  tl.fromTo(
    document.querySelector(".re .w-tab-menu"),
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      y: 0,
      duration: 1.5,
      ease: springBubble,
    }
  )
    .fromTo(
      document.querySelectorAll(".re .w-dyn-item"),
      {
        opacity: 0,
        scale: 0.8,
        y: 100,
      },
      {
        scale: 1,
        opacity: 1,
        y: 0,
        duration: 1.5,
        stagger: 0.08,
        ease: springBubble,
      },
      "-=1.8"
    )
    .fromTo(
      document.querySelector(".re .view-more---button"),
      {
        opacity: 0,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: springBubble,
      },
      "-=2.3"
    );
}
