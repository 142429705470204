import springer from "springer";

export async function hero() {
  const heroContainers = document.querySelector(
    ".hero-top-bot, .hero-team---section"
  );

  if (!heroContainers) return;

  const title = heroContainers.querySelector(
    ".h-30px---serif, .h-36px---serif"
  );
  const text = heroContainers.querySelector(".body---book");
  const img = heroContainers.querySelector(
    ".image-tb, .wrapper-image---hero-team"
  );

  title.style.opacity = "0";

  title.style.opacity = "1";

  let words = new SplitType([title], {
    types: "words",
    tagName: "span",
  });

  const springBubble = springer(0.5, 0.65);
  const springText = springer(5, 0.12);

  const tl = gsap.timeline();

  tl.fromTo(
    img,
    {
      opacity: 0,
      scale: 0.8,
      y: 100,
    },
    {
      scale: 1,
      opacity: 1,
      y: 0,
      duration: 1.5,
      stagger: {
        amount: 0.32,
      },
      ease: springBubble,
    }
  )
    .fromTo(
      text,
      {
        opacity: 0,
        y: 15,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: springText,
      },
      "-=1.5"
    )
    .fromTo(
      gsap.utils.toArray(".word"),
      {
        opacity: 0,
        y: 15,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        stagger: {
          each: 0.045,
        },
        ease: springText,
      },
      "-=1.5"
    );

  const illustration = document.querySelector(".hero-top-bot .image-61");

  if (illustration) {
    const tlScroll = gsap.timeline({
      scrollTrigger: {
        trigger: heroContainers,
        start: "top+=200px top",
        end: "bottom top",
        scrub: true,
      },
    });

    tlScroll.fromTo(
      illustration,
      {
        bottom: "-175px",
        rotate: "30",
      },
      {
        bottom: "0px",
        rotate: 0,
        ease: "power4.out",
      }
    );
  }
}
