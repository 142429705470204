export class EasingFunctions {
  // Fonction d'easing Linear
  static linear(t) {
    return t;
  }

  // Fonction d'easing easeInQuad
  static easeInQuad(t) {
    return t * t;
  }

  // Fonction d'easing easeOutQuad
  static easeOutQuad(t) {
    return 1 - (1 - t) * (1 - t);
  }

  // Fonction d'easing easeInOutCubic
  static easeInOutCubic(t) {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  }

  // Fonction d'easing easeInOutQuint
  static easeInOutQuint(t) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 - Math.pow(-2 * t + 2, 5) / 2;
  }

  static easeOutExpo(t) {
    return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
  }

  static spring(t, tension = 0.2, friction = 0.8) {
    const w0 = Math.sqrt(tension);
    const w1 = w0 * Math.sqrt(friction);
    const wD = w1 - w0;
    const A = 1;
    const B = -wD / (2 * w1);
    const C = (wD * wD) / (4 * w1 * w1) - 1;

    return (
      A * Math.exp(-w1 * t) * (B * Math.sin(w0 * t) + C * Math.cos(w0 * t))
    );
  }
}
