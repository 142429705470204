function animateWords(element) {
  let typeSplit = new SplitType(element, {
    types: "words",
    tagName: "span",
  });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: element,
        start: "top+=10% bottom",
        end: "center center",
        scrub: true,
      },
    })
    .fromTo(
      element.querySelectorAll(".word"),
      {
        opacity: 0.15,
      },
      {
        opacity: 1,
        y: 0,
        stagger: 0.2,
      }
    );
}

export async function scrubWordsAnimation() {
  const scrub = document.querySelectorAll(".scrub");
  if (scrub.length === 0) return;
  if (scrub) {
    scrub.forEach((el) => {
      animateWords(el);
    });
  }
}
