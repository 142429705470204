import { loadScript } from "../libs/loader.js";
import { isMobile } from "../libs/devices.js";

export function youtube() {
  if (!document.querySelectorAll(".lsi-in---section, .top-content-card---push-video"))
    return;

  const videoSectionList = document.querySelectorAll(
    ".lsi-in---section, .top-content-card---push-video"
  );

  videoSectionList.forEach((videoSection) => {
    const youtubeSrc = videoSection.getAttribute("data-youtube");
    let popup = videoSection.querySelector(".popup-content");
    let player;

    videoSection.addEventListener("click", async (e) => {
      e.currentTarget.classList.add("active");
      popup = document.querySelector(".popup-content");
      const popupWrapper = document.querySelector(".popup-wrapper");
      gsap.set(popupWrapper, {
        display: "block",
        opacity: 0,
      });
      gsap.fromTo(
        popupWrapper,
        {
          opacity: 0,
          display: "block",
          willChange: "opacity, transform",
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          ease: "power4.out",
          duration: 1,
        }
      );

      // Disable scrolling
      document.body.style.overflow = "hidden";

      const height = window.innerWidth * (16 / 9);

      popup.style = `aspect-ratio:16/9; height:auto`;
      popup.parentNode.style =
        "max-width: 80%; max-height: 80%;position:absolute;top:5%;left:10%;right:0;bottom:0;height:100%;width:100%;";

      await loadScript("js", "https://www.youtube.com/iframe_api");

      window.setTimeout(() => {
        console.log("click, click, click");
        player = new YT.Player(popup, {
          height: "100%",
          width: "100%",
          videoId: youtubeSrc.replace("https://www.youtube.com/watch?v=", ""),
          playerVars: {
            fs: 0,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
            controls: 2,
            autoplay: 1,
            playsinline: 1,
            enablejsapi: 1,
            cc_load_policy: 0,
          },
          events: {
            onReady: onPlayerReady,
          },
        });
        function onPlayerReady(event) {
          event.target.playVideo();
        }
      }, 100);
      document.addEventListener("keydown", keyPressedHandler);
    });

    function closePopup() {
      const popupWrapper = videoSection.querySelector(".popup-wrapper");
      const slides = Array.from(
        document.querySelectorAll(".slide---push-video")
      );
      slides.forEach((slide) => {
        slide.classList.remove("active");
      });
      gsap.to(popupWrapper, {
        opacity: 0,
        y: 100,
        ease: "power4.out",
        onStart: () => {
          videoSection.querySelector(".popup-content-wrapper").innerHTML = "";
        },
        onComplete: () => {
          const video = document.createElement("div");
          video.classList.add("popup-content");
          video.classList.add("has-video");
          videoSection
            .querySelector(".popup-content-wrapper")
            .appendChild(video);
          popupWrapper.style.display = "none";

          // Enable scroll and maintain scroll position
          const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
          document.body.style.overflow = "";
          window.scrollTo(0, scrollTop);
        },
      });
      document.removeventListener("keydown", keyPressedHandler);
    }

    function keyPressedHandler(e) {
      if (e.key === "Escape" || e.keyCode === 27) {
        videoSection.querySelector(".popup-close").click();
      }
    }

    videoSection.querySelector(".popup-close").addEventListener(
      "click",
      (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.setTimeout(closePopup(), 100);
      },
      false
    );
    videoSection.querySelector(".popup-background").addEventListener(
      "click",
      (e) => {
        e.stopPropagation();
        window.setTimeout(closePopup(), 100);
      },
      false
    );
  });
}
