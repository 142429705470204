import springer from "springer";

export function mission() {
  const missionSection = document.querySelector(".mission---section");
  if (!missionSection) return;


  const springBubble = springer(0.5, 0.65);

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: missionSection,
      start: "300px bottom",
      scrub: false,
    },
  });

  tl.fromTo(
    document.querySelectorAll(".mission---section .h-30px---serif"),
    { opacity: 0, y: 50},
    {
      opacity: 1,
      scale: 1,
      y: 0,
      ease: springBubble,
      duration: 1,
    }
  ).fromTo(
    document.querySelectorAll(".row---mission"),
    { opacity: 0, scale: 0.85, y: 50 },
    {
      opacity: 1,
      scale: 1,
      stagger: 0.1,
      y: 0,
      ease: springBubble,
      duration: 1,
    }, "-=1"
  ).fromTo(
    document.querySelector(".bot-content---mission"),
    { opacity: 0, y: 50 },
    {
      opacity: 1,
      y: 0,
      ease: springBubble,
      duration: 1,
    }, "-=1"
  ).fromTo(
    document.querySelectorAll(".small-text---book"),
    { opacity: 0},
    {
      opacity: 1,
      stagger: 0.05,
      ease: springBubble,
      duration: 0.2,
    }, "-=1"
  );
}
