import springer from "springer";
export function lsiFor() {
  const sections = document.querySelectorAll(".lsi-for---section");
  if (sections.length === 0) return;
  sections.forEach((section) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top-=10px bottom",
        end: "center center",
        // scrub: true,
        once: true,
      },
    });
    const springBubble = springer(0.5, 0.6);
    tl.add(
      gsap.fromTo(
        section.querySelectorAll(".collection-item-5"),
        {
          opacity: 0,
          scale: 0.9,
          y: 50,
          overflow: "hidden",
          willChange: "transform, opacity",
        },
        {
          y: 0,
          scale: 1,
          opacity: 1,
          stagger: 0.2,
          duration: 1.5,
          ease: springBubble,
        }
      ),
      0
    );
    tl.add(
      gsap.fromTo(
        section.querySelectorAll("img"),
        {
          opacity: 0,
          scale: 0.95,
          y: 150,
          willChange: "transform, opacity",
        },
        {
          y: 0,
          scale: 1,
          opacity: 1,
          delay: 0.15,
          duration: 1.5,
          stagger: 0.1,
          ease: springBubble,
        }
      ),
      0
    );
    tl.add(
      gsap.fromTo(
        section.querySelectorAll(".bot-content-card---lsi-for"),
        {
          opacity: 0,
          scale: 0.5,
          y: 250,
          willChange: "transform, opacity",
        },
        {
          y: 0,
          scale: 1,
          opacity: 1,
          delay: 0.25,
          duration: 1.5,
          stagger: 0.1,
          ease: springBubble,
        }
      ),
      0
    );
  });
}
