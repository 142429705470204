export function list() {
  const olElements = document.querySelectorAll(
    ".article-container ol, .article-container-2 ol, .article-container-3 ol, .article-container-760 ol"
  );
  if (!olElements) return;

  olElements.forEach((olElement) => {
    const liElements = olElement.querySelectorAll("li");
    if (!liElements) return;

    const listWrapperDiv = document.createElement("div");
    listWrapperDiv.classList.add("list-wrapper");

    const listOl = document.createElement("ol");
    listOl.classList.add("list-container");

    liElements.forEach((liElement, index) => {
      const titleElement = liElement.querySelector("em");

      const listNumberDiv = document.createElement("div");
      listNumberDiv.classList.add("list-number");
      listNumberDiv.textContent = index + 1;

      const listContentDiv = document.createElement("div");
      listContentDiv.classList.add("list-content");

      if (titleElement) {
        const listContainerTitle = document.createElement("div");
        listContainerTitle.classList.add("list-title");
        listContainerTitle.appendChild(titleElement);
        listContentDiv.appendChild(listContainerTitle);
      }

      const contentParagraph = document.createElement("p");
      contentParagraph.innerHTML = titleElement ? liElement.innerHTML.replace(titleElement.outerHTML, "") : liElement.innerHTML;
      listContentDiv.appendChild(contentParagraph);

      const listLi = document.createElement("li");
      listLi.classList.add("list");
      listLi.appendChild(listNumberDiv);
      listLi.appendChild(listContentDiv);

      listWrapperDiv.appendChild(listLi);
    });

    listOl.appendChild(listWrapperDiv);

    olElement.insertAdjacentElement("beforebegin", listOl);
    olElement.remove();
  });
}
