import { loadScript } from "../libs/loader.js";
import springer from "springer";

export async function prices() {
  const cards = document.querySelectorAll(".card---price-hero");

  if (!cards) {
    return;
  }

  const title = document.querySelector(".hero-price---section .h-30px---serif");

  if (!title) return;

  const pricingWrapper = document.querySelector(".wrapper---princing");
  const pricingHeader = document.querySelectorAll(
    ".header-pricing, .wrapper-header-pricing"
  );
  const pricingRows = document.querySelectorAll(".row-princing");

  title.style.opacity = "0";

  await loadScript(
    "js",
    "https://cdn.jsdelivr.net/npm/split-type@0.3.3/umd/index.min.js"
  );

  title.style.opacity = "1";

  let words = new SplitType([title], {
    types: "words",
    tagName: "span",
  });

  const springBubble = springer(0.5, 0.65);
  const springText = springer(5, 0.12);

  const tl = gsap.timeline();

  tl.fromTo(
    cards,
    {
      opacity: 0,
      scale: 0.8,
      y: 100,
    },
    {
      scale: 1,
      opacity: 1,
      y: 0,
      duration: 1.5,
      stagger: {
        amount: 0.32,
      },
      ease: springBubble,
    }
  ).fromTo(
    gsap.utils.toArray(".word"),
    {
      opacity: 0,
      y: 15,
    },
    {
      opacity: 1,
      y: 0,
      duration: 0.8,
      stagger: {
        each: 0.045,
      },
      ease: springText,
    },
    "-=1.8"
  );

  const tlScrollTrigger = gsap.timeline({
    scrollTrigger: {
      trigger: pricingWrapper,
      start: "top center",
      scrub: false,
    },
  });

  tlScrollTrigger
    .fromTo(
      pricingHeader,
      {
        opacity: 0,
        scale: 0.9,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 1.2,
        ease: springBubble,
      }
    )
    .fromTo(
      pricingRows,
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        stagger: {
          amount: 0.4,
        },
        ease: springBubble,
      },
      "0.2"
    );
}
